import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { parentalRequestTranslationConfig } from '../../app.config';
import { Recourse } from '../../enums';
import ParentalRequestBroadcast from './components/ParentalRequestBroadcast';
import useModal from '../../hooks/useModal';
import parentalRequestConstants from './constants/parentalRequestConstants';
import ParentEmailModal from './components/ParentEmailModal';
import { RecourseResponse } from '../../types/AmpTypes';

const ParentalRequestContainer = ({
  translate,
  recourse,
  onHidecallback,
  value = null
}: {
  translate: WithTranslationsProps['translate'];
  recourse: RecourseResponse;
  onHidecallback: () => void;
  value: Record<string, unknown> | null;
}): JSX.Element => {
  const { emailSentConfirmation } = parentalRequestConstants.translationKeys;
  const { action: recourseAction, parentConsentTypes } = recourse;
  const consentType = parentConsentTypes[0];
  const [confirmationModal, confirmationModalService] = useModal({
    translate,
    title: translate(emailSentConfirmation.title),
    // TODO: Will update when copy is ready
    body: <p>{translate(emailSentConfirmation.body)}</p>,
    actionButtonTranslateKey: emailSentConfirmation.btnText,
    onAction: () => {
      confirmationModalService.close();
      onHidecallback();
    },
    onHide: onHidecallback
  });

  return (
    <div>
      {recourseAction === Recourse.ParentConsentRequest && (
        <ParentalRequestBroadcast
          translate={translate}
          consentType={consentType}
          successCallBack={confirmationModalService.open}
          onHideCallback={onHidecallback}
          value={value}
        />
      )}
      {recourseAction === Recourse.ParentLinkRequest && (
        <ParentEmailModal
          translate={translate}
          consentType={consentType}
          successCallback={confirmationModalService.open}
          onHidecallback={onHidecallback}
          value={value}
        />
      )}
      {confirmationModal}
    </div>
  );
};

export default withTranslations(ParentalRequestContainer, parentalRequestTranslationConfig);
