import { eventStreamService, localStorageService } from 'core-roblox-utilities';
import { httpService } from 'core-utilities';
import ParentalConsentType from '../enums/ParentalConsentType';
import parentalRequestConstants from '../constants/parentalRequestConstants';
import { newParentalRequest, broadcastParentalRequest } from '../constants/urlConstants';

type TNewParentalRequestParams = {
  email: string;
  requestType: ParentalConsentType;
  requestDetails?: Record<string, unknown>;
};

type TParentalRequestResponse = {
  lockedUntil: string;
  sessionId: string;
};

type TBoadcastParentalRequestParams = {
  requestType: ParentalConsentType;
  requestDetails?: Record<string, unknown>;
};

const { events } = parentalRequestConstants;
const getContext = (consentType: ParentalConsentType) => {
  switch (consentType) {
    case ParentalConsentType.ChargebackReenableAccount:
      return events.chargebackContext;
    case ParentalConsentType.SavePaymentMethods:
      return events.savePaymentMethodsContext;

    default:
      return events.chargebackContext;
  }
};

const generateState = (sessionId?: string) => {
  return `sessionId: ${sessionId ?? events.state.unknown}`;
};
export const parentalRequestEventService = {
  sendParentEmailSubmitEvent: (consentType: ParentalConsentType, sessionId?: string): void => {
    const context = getContext(consentType);

    eventStreamService.sendEventWithTarget(
      events.eventName.authButtonClick,
      context.parentalEntry,
      {
        btn: events.btn.submit,
        associatedText: events.text.submit,
        state: generateState(sessionId)
      }
    );
  },
  sendInteractParentEmailFormEvent: (consentType: ParentalConsentType): void => {
    const context = getContext(consentType);
    eventStreamService.sendEventWithTarget(
      events.eventName.authFormInteraction,
      context.parentalEntry,
      {
        field: events.field.email,
        associatedText: events.text.enterParentEmail
      }
    );
  }
};

export const parentalRequestService = {
  sendRequestToNewParent: async (
    params: TNewParentalRequestParams
  ): Promise<TParentalRequestResponse> => {
    const urlConfig = { url: newParentalRequest, withCredentials: true };
    const { requestDetails } = params;
    const response = await httpService.post<TParentalRequestResponse>(urlConfig, params);
    if (requestDetails) {
      const settingName = Object.keys(requestDetails)[0];
      localStorageService.setLocalStorage(
        `Roblox.ParentalRequest.${settingName}CooldownExpirationTime`,
        response.data.lockedUntil
      );
    }
    return response.data;
  },
  sendRequestToAllParents: async (
    params: TBoadcastParentalRequestParams
  ): Promise<TParentalRequestResponse> => {
    const urlConfig = { url: broadcastParentalRequest, withCredentials: true };
    const { requestDetails } = params;

    const response = await httpService.post<TParentalRequestResponse>(urlConfig, params);
    if (requestDetails) {
      const settingName = Object.keys(requestDetails)[0];
      localStorageService.setLocalStorage(
        `Roblox.ParentalRequest.${settingName}CooldownExpirationTime`,
        response.data.lockedUntil
      );
    }
    return response.data;
  }
};
