import { ModalEvent } from '../accessManagement/constants/viewConstants';
import { Access } from '../enums';
import { ExtraParameter } from '../types/AmpTypes';

const isFeatureAccessGranted = (access: Access) => {
  return access === Access.Granted;
};

export const startAccessManagementUpsell = async (
  featureName: string,
  redirectLink: string,
  extraParameters: ExtraParameter[] = null,
  isAsyncCall = true,
  usePrologue = false
): Promise<boolean> => {
  return new Promise(resolve => {
    const event = new CustomEvent(ModalEvent.StartAccessManagementUpsell, {
      detail: {
        featureName,
        redirectLink,
        extraParameters,
        isAsyncCall,
        usePrologue,
        closeCallback: (access: Access) => {
          resolve(isFeatureAccessGranted(access));
        }
      }
    });
    window.dispatchEvent(event);
  });
};

export default {
  startAccessManagementUpsell
};
