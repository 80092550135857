import { EnvironmentUrls } from 'Roblox';

const parentalRequestConstants = {
  privacyPolicyUrl: `${EnvironmentUrls.websiteUrl}/info/privacy`,
  chargebackWizardSessionTokenLocalStorageKey: 'Roblox.ChargebackWizardSessionToken',
  defaultCooldownTimeInMs: 900000, // 15 minutes,
  emailRegex: '^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$',

  translationKeys: {
    gatherParentEmail: {
      title: 'Title.EnterParentEmail',
      body: 'Description.EnterParentEmailV3',
      footer: 'Description.ParentalEmailFooter',
      unknownError: 'Message.SomethingWentWrong',
      invalidEmailError: 'Message.InvalidEmail',
      btnText: 'Action.Submit',
      emailPlaceholder: 'Label.Email',
      emailTooManyChildrenError: 'Message.EmailIneligible',
      childTooManyParentsError: 'Message.ExistAccountWithEmail',
      emailTooManyRequest: 'Message.TooManyAttempts',
      alreadyApplied: 'Description.AllSet',
      emailNotUnique: 'Message.EmailNotUniqueForChild', // TODO: need to be created
      cancelPendingRequest: 'Message.CancelPendingRequest', // TODO: need to be created
      forgottenParent: 'Message.ForgottenParent' // TODO: need to be created
    },
    emailSentConfirmation: {
      title: 'Title.Thanks',
      body: 'Description.EmailSent',
      btnText: 'Action.OK'
    },
    exitWarning: {
      title: 'Title.AreYouSure',
      body: 'Description.ExitEnterEmail',
      actionBtnText: 'Action.EnterEmail',
      neutralBtnText: 'Action.Cancel'
    }
  },
  events: {
    chargebackContext: {
      parentalEntry: 'parentalEntry'
    },
    savePaymentMethodsContext: {
      parentalEntry: 'parentalEntryDevsubs'
    },
    eventName: {
      authPageLoad: 'authPageload',
      authButtonClick: 'authButtonClick',
      authFormInteraction: 'authFormInteraction',
      authMsgShown: 'authMsgShown',
      authModalShown: 'authModalShown'
    },
    state: {
      sessionId: 'sessionId',
      wizardId: 'wizardId',
      unknown: 'unknown'
    },
    field: {
      email: 'email',
      errorMessage: 'errorMessage',
      logoutPopup: 'logoutPopup',
      parentalEmailMismatch: 'parentalEmailMismatch'
    },
    btn: {
      unlock: 'unlock',
      setup: 'setup',
      submit: 'submit'
    },
    text: {
      enterParentEmail: 'Enter Parent Email',
      submit: 'Submit',
      requestExpired: 'Request expired',
      youreAllSet: "You're all set",
      u18Error: 'U18 error = 0 (both declared & IDV)',
      logOut: 'Log Out',
      cancel: 'Cancel',
      emailMismatch: 'Email Mismatch'
    }
  }
};

export default parentalRequestConstants;
