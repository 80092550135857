import { Recourse } from '../enums';
import ParentalConsentType from '../recourses/parentalRequest/enums/ParentalConsentType';

export type RecourseResponse = {
  action: Recourse;
  parentConsentTypes?: ParentalConsentType[];
};

export type ExtraParameter = {
  name: string;
  type: string;
  value: string;
};

export function convertExtraParametersToDictionary(
  extraParameters: ExtraParameter[]
): Record<string, any> {
  return extraParameters.reduce((dictionary, extraParameter) => {
    if (extraParameter) {
      dictionary[extraParameter.name] = extraParameter.value;
    }
    return dictionary;
  }, {} as Record<string, any>);
}
